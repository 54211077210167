.footer-footer {
  width: 100%;
  display: flex;
  position: relative;
  max-width: var(--dl-size-size-maxwidth);
  align-items: flex-start;
  padding-left: var(--dl-space-space-oneandhalfunits);
  margin-bottom: var(--dl-space-space-fourunits);
  padding-right: var(--dl-space-space-oneandhalfunits);
  flex-direction: row;
  justify-content: space-between;
}
.footer-information {
  gap: var(--dl-space-space-fiveunits);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.footer-content {
  gap: var(--dl-space-space-twounits);
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.footer-header {
  gap: var(--dl-space-space-twounits);
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.footer-text {
  color: rgb(18, 40, 33);
  width: 100%;
  font-size: 18px;
  max-width: 600px;
  font-family: Lato;
  line-height: 27px;
}
.footer-contact-list {
  gap: var(--dl-space-space-oneandhalfunits);
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.footer-contact {
  gap: 11px;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.footer-image {
  width: 24px;
  height: 18px;
  object-fit: cover;
}
.footer-text01 {
  color: rgb(18, 40, 33);
  width: 100%;
  font-size: 18px;
  font-family: Lato;
  line-height: 18px;
}
.footer-contact1 {
  gap: 11px;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.footer-image1 {
  width: 18px;
  height: 24px;
  object-fit: cover;
  margin-left: 3px;
  margin-right: 3px;
}
.footer-text02 {
  color: rgb(18, 40, 33);
  width: 100%;
  font-size: 18px;
  font-family: Lato;
  line-height: 18px;
}
.footer-copyright {
  color: rgb(18, 40, 33);
  width: 100%;
  font-size: 14px;
  font-family: Lato;
  line-height: 21px;
}
.footer-links {
  gap: 110px;
  display: flex;
  align-items: flex-start;
  padding-right: 135px;
  flex-direction: row;
}
.footer-column {
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.footer-column1 {
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
@media(max-width: 991px) {
  .footer-footer {
    gap: var(--dl-space-space-threeunits);
    align-items: flex-start;
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
    flex-direction: column;
    justify-content: flex-start;
  }
  .footer-content {
    gap: var(--dl-space-space-oneandhalfunits);
  }
  .footer-header {
    gap: var(--dl-space-space-unit);
  }
  .footer-copyright {
    display: none;
  }
  .footer-links {
    gap: var(--dl-space-space-threeunits);
    padding-right: 0px;
    flex-direction: column;
  }
}
